// extracted by mini-css-extract-plugin
export var appearance_default = "BlogPostMediaObject-module--appearance_default--+6cyF";
export var appearance_hero = "BlogPostMediaObject-module--appearance_hero--oz+kE";
export var appearance_small = "BlogPostMediaObject-module--appearance_small--q7cXD";
export var author = "BlogPostMediaObject-module--author--GaMuh";
export var category = "BlogPostMediaObject-module--category--DVJEX";
export var content = "BlogPostMediaObject-module--content--c5Lho";
export var description = "BlogPostMediaObject-module--description--aZgGA";
export var image = "BlogPostMediaObject-module--image--vzi19";
export var link = "BlogPostMediaObject-module--link--RDuZy";
export var root = "BlogPostMediaObject-module--root--WwS7l";
export var title = "BlogPostMediaObject-module--title--VzQBv";
export var wrapper = "BlogPostMediaObject-module--wrapper--J1COr";